import { StepMatrixType } from "../../features/statistics/store/statistics.state";
import Api from "../axios";

export async function getStepStatsReq({stepId, office, period, userId} : {stepId : string, userId: string, office: string, period: "day" | "week" | "month"}): Promise<StepMatrixType>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `steps/step-statistics/${stepId}`, data: { office, period, userId }})
    const { error } = response
    if(error == null) {
      const x = [], y = [];
      for (const el of response.result) {
        x.push(new Date(el.date))
        y.push(el.count)
      }
      return {x, y}
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON get');
    console.error({error});
    throw error;
  }
}