import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getStepStatsReq } from "../../../app/services/stats.service"
import { StatisticsState } from "./statistics.state"
const initialState: StatisticsState = {
  loading: false,
  loadingStepStats: false,
  formStepStats: {
    stepId: "",
    office: "",
    period: "day",
    userId: "",
  },
  stepGraphData: {x: [], y: []},
}
export const getStepStatsThunk = createAsyncThunk( "Statistics/getStepStatsThunk", async ({stepId, office, period, userId} : {stepId : string, userId: string, office: string, period: "day" | "week" | "month"}) =>  await getStepStatsReq({stepId, office, period, userId}))



export const statisticsSlice = createSlice({
  name: "Statistics",
  initialState,
  reducers: {
    changeInputStepStatsAct: (state, action: PayloadAction<{name: string, val: string}>) => {
      state.formStepStats[action.payload.name] = action.payload.val
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStepStatsThunk.fulfilled, (state, action) => {
      state.stepGraphData = action.payload
    })  
    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("Statistics"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("Statistics"), (state) => {
      state.loading = false
    })
  },
})
export const { changeInputStepStatsAct } =statisticsSlice.actions
export default statisticsSlice.reducer