import { useAppDispatch, useAppSelector } from "../app/hooks"
import CollectorFormDialog from "../features/collectors/components/collector-form-dialog"
import CollectorOfficesDialog from "../features/collectors/components/collector-offices-dialog"
import CollectorsControls from "../features/collectors/components/collectors-controls"
import CollectorsList from "../features/collectors/components/collectors-list"

export default function CollectorsView() {
  const dispatch = useAppDispatch()
  const {} = useAppSelector((state) => state.users) 
  return (
    <>
      <CollectorsControls/>
      <CollectorFormDialog/>
      <CollectorsList/>
      <CollectorOfficesDialog/>
    </>
  )
}