import { useAppSelector } from "../app/hooks";
import StepsGraph from "../features/statistics/components/step-graph";

export default function DashboardContent() {
  const { currentUser } = useAppSelector((state) => state.login)
  
  return (
    <>
      <h1>Dashboard</h1>
      {currentUser?.level === 0 && <StepsGraph />}
    </>
  )
}